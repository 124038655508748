export default {
    data() {
        return {
            show: false,
            enterprise_name: '',
            mobile: '',
            code: '',
            password: '',
            addr_detail: '',
            status: '1',
            re_password: '',
            second: 60,
            cutdown: false,
            type:0,
            id:''
        }
    },
    created() {

    },
    methods: {
        close: function () {
            this.show = false;
        },
        add: function () {
            this.show = true;
            this.type = 0;
        },
        edit: function (index) {
            this.show = true;
            this.type = 1;
            let childUserList = this.childUserList;
            this.enterprise_name = childUserList[index].enterprise_name;
            this.mobile = childUserList[index].mobile;
            // this.password = childUserList[index].password;
            this.id = childUserList[index].id;
            this.addr_detail = childUserList[index].addr_detail;
            this.status = childUserList[index].status +'';
            // this.re_password = childUserList[index].re_password;
        },
        addChildUser: function () {
            let formData = this.type == 0 ?{
                enterprise_name: this.enterprise_name,
                mobile: this.mobile,
                code: this.code,
                password: this.password,
                addr_detail: this.addr_detail,
                status: this.status,
                re_password: this.re_password,
                token: true
            }:{
                enterprise_name: this.enterprise_name,
                mobile: this.mobile,
                id: this.id,
                password: this.password,
                addr_detail: this.addr_detail,
                status: this.status,
                re_password: this.re_password,
                token: true 
            }
          
            let api = this.type == 0 ?'/api/user/addChildUser.html':'/api/user/editChildUser.html'
            this.$post(api, formData).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.show = false
                    this.getChildUserList();
                    return this.$message('子账号添加成功');
                } else {
                    return this.$message(res.msg);
                }
               
            }).finally(() => {

            })
        },
        getCode: function () {
            if (this.cutdown) return

            this.$post('/api/user/sendSms.html', { phone: this.mobile }).then((res) => {
                if (res.code == 200) {
                    this.countDown();
                } else {
                    ;

                }
                return this.$message(res.msg);
            }).finally(() => {

            })
        },
        countDown: function () {
            this.cutdown = true
            let setInt = setInterval(() => {
                this.second--
                console.log(this.second)
                if (this.second == 0) {
                    console.log(3123)
                    clearInterval(setInt)
                    this.second = 60;
                    this.cutdown = false;
                }

            }, 1000)
        },

    }
}