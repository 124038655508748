<template>
  <div class="child-account">
    <div class="cf">
      <div class="h6 fl">子账号管理</div>
      <div class="add-btn fr" @click="add()">添加</div>
    </div>
    <div class="content">
      <ul class="t-header cf">
        <li class="t-item fl">用户名</li>
        <li class="t-item fl">地址</li>
        <li class="t-item fl">手机号</li>
        <li class="t-item fl">状态</li>
        <li class="t-item fl">操作</li>
      </ul>
      <ul class="tb cf" v-for="(item, index) in childUserList" :key="index">
        <li class="tb-item fl">{{item.enterprise_name}}</li>
        <li class="tb-item fl">{{item.addr_detail}}</li>
        <li class="tb-item fl">{{item.mobile}}</li>
        <li class="tb-item fl" :style="{color:item.status == 1?'':'#F51313'}">{{item.status == 1? '启用':'停用'}}</li>
        <li class="tb-item fl cf">
          <button class="btn " @click="getOrder(index,item.id)">订单列表</button>
          <button class="btn" @click="edit(index)">编辑</button>
          <button class="btn" @click="deleteChild(index,item.id)">删除</button>
        </li>
      </ul>
    </div>
    <div class="tc-bg" v-if="show">
      <div class="tc-box">
        <div class="tc-inbox">
          <div class="h5">编辑子账户</div>
          <img
            @click="close"
            class="close"
            src="../../../../../assets/images/common_iocn_guanbi.png"
            alt=""
          />
          <div class="cf">
            <div class="inp-box fl">
              <div class="label">用户名</div>
              <input
                class="inp"
                type="text"
                v-model="enterprise_name"
                placeholder="请输入用户名称"
                name=""
                id=""
              />
            </div>
            <div class="inp-box fr">
              <div class="label">企业地址</div>
              <input
                class="inp"
                type="text"
                v-model="addr_detail"
                placeholder="请输入企业地址"
                name=""
                id=""
              />
            </div>
            <div class="icon-inpbox cf fl">
              <img
                class="fl"
                src="../../../../../assets/images/denglu_iocn_shouji.png"
                alt=""
              />
              <el-input
                class="fl"
                placeholder="请输入手机号"
                v-model="mobile"
              ></el-input>
            </div>
            <div class="icon-inpbox cf fr">
              <img
                class="fl"
                src="../../../../../assets/images/zhuce_iocn_yzm.png"
                alt=""
              />
              <el-input
                class="fl code-inp"
                placeholder="请输入验证码"
                v-model="code"
              ></el-input>
              <div class="get-code fl" @click="getCode">  {{ cutdown ? second + "秒后重新发送" : "获取验证码" }}</div>
            </div>
            <div class="icon-inpbox cf fl">
              <img
                class="fl"
                src="../../../../../assets/images/denglu_iocn_mima.png"
                alt=""
              />
              <el-input
                class="fl"
                placeholder="请输入登录密码"
                v-model="password"
                show-password
              ></el-input>
            </div>
            <div class="icon-inpbox cf fr">
              <img
                class="fl"
                src="../../../../../assets/images/denglu_iocn_mima.png"
                alt=""
              />
              <el-input
                class="fl"
                placeholder="再次输入登录密码"
                v-model="re_password"
                show-password
              ></el-input>
            </div>
          </div>
          <div class="radio-box">
            <div class="label">账号状态</div>
            <div class="cf">
              <el-radio class="fl" v-model="status" label="1">启用</el-radio>
              <el-radio class="fl" v-model="status" label="2">停用</el-radio>
            </div>
          </div>
          <button class="over-btn" @click="addChildUser">完成</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
    mixins
};
</script>
<style scoped>
@import "../style.css";
</style>