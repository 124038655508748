export default{
    data: function () {
        return {
            childUserList:[],
            enterprise_name: '',
            enterprise_mobile: '',
            addr_detail: '',
            enterprise_email: '',
            enterprise_contact: '',
            contact_job: '',
            enterprise_createtime: '',
            enterprise_numbers: '',
            license_img: '',
            business_info: '',
            secrecy_img: '',
            three_img: '',
            iso_img: '',
            rohs_img: '',
            ce_img: '',
            is_secrecy: '1',
            usertype:'1'
        }
    },
    created(){
       this.getChildUserList()
        this.setData();
    },
    methods: {
        setData: function () {
            let userInfo = JSON.parse(localStorage['user']);
            console.log(userInfo, 123123);
                // this.enterprise_name = userInfo.enterprise_name || ''
                this.enterprise_mobile = userInfo.enterprise_mobile || ''
                this.addr_detail = userInfo.enterprise_addr || ''
                this.enterprise_email = userInfo.enterprise_email || ''
                this.enterprise_contact = userInfo.enterprise_contact || ''
                this.contact_job = userInfo.contact_job || ''
                this.enterprise_createtime = userInfo.enterprise_createtime || ''
                this.enterprise_numbers = userInfo.enterprise_numbers || ''
                // this.license_img = userInfo.license_img
                this.usertype = userInfo.user_type|| ''
        },
        getChildUserList: function () {
            this.$post('/api/user/childUserList.html', {token:true}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.childUserList  = res.data || []      
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        deleteChild:function(index,id){
            this.$confirm('此操作将删除子账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$post('/api/user/delChildInfo.html', {token:true,id:id}).then((res) => {
                    console.log(res)
                    if (res.code == 200) {
                        this.childUserList.splice(index,1)  
                    }else{
                        return this.$message(res.msg);
                    }
                }).finally(() => {
    
                })
              }).catch(() => {
                        
              });
        },getOrder:function (index,id) {
           this.$router.push({
                path: '/user-center/order',
                query: {
                    sub: id,
                }
            })

            // this.$router.push({ path: '/user-center/order?sub='+id })
        }
    },
}